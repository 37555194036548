import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';
import Header from '../components/headerEn';
import Footer from '../components/footerEn';
import Contact from '../components/contactEn';
import SEO from "../components/seo";

class ServiceEnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      cover: true,
      targetVideo: "",
    }
  }

  hoverOn = (id) => {
    console.log("mouse over" + id);
    this.setState({
      cover: false,
      targetVideo: id,
    })
  }

  hoverOff = (id) => {
    console.log("mouse off" + id);
    this.setState({
      cover: true,
      targetVideo: id,
    })
  }

  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Services" />
        <Header />
        <Contact />

        <section>
          <div className="video-banner">
            <video 
              autoPlay
              muted
              loop
              style={{ height:"100vh", width:"100%", objectFit:"cover", position:"absolute" }}
            >
              <source src="/static/videos/products_background_oumsbh.mp4" type="video/mp4"></source>
            </video>
            <div className="banner-second-main" >
              <h1 className="main-banner-title">Making Reality <br />Surpass <br />Your Imagination.</h1>
            </div>
          </div>
        </section>

        <div className="page-content">
          <section className="section">
            <div className="container w-container">
              <div className="w-row">
                <div className="w-col w-col-12 w-col-stack">
                  <div className="w-dyn-list">
                    {data.allStrapiService.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "English") ?
                      <div className="w-dyn-items">
                        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                          <ReactMarkdown source={document.node.description} />
                        </ScrollAnimation>
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section more-padding" style={{ background:"#232323"}}>
            <div className="container w-container">
              <h3 className="invert-text-color" style={{ marginBottom:"1em" }}>Security Features</h3>
              {data.allStrapiMotion.edges.map(document => (
                <div key={document.node.id}>
                  {(document.node.label === "Security" && document.node.language === "English") ?
                  <div className="w-row">
                    <div className="w-col w-col-4 w-col-stack">
                      <div className="product-wrapper w-col w-col-3 w-col-medium-4 w-col-small-6" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                        <div onMouseEnter={() => this.hoverOn(document.node.id)} onMouseLeave={() => this.hoverOff(document.node.id)}>
                          <ReactPlayer
                            url={document.node.video_url.replace("/upload/", "/upload/c_scale,h_250,q_auto:best,w_250/")}
                            playing
                            loop
                            muted
                            width='250px'
                            height='250px'
                            preloading="true"
                            playbackRate={2}
                          />
                          { this.state.cover === false && this.state.targetVideo === document.node.id 
                            ? <div className="security-video-cover" style={{ visibility:"hidden", opacity: 0 }}> 
                                <Img fluid={document.node.preview.childImageSharp.fluid} />
                              </div>
                            : <div className="security-video-cover">
                                <Img fluid={document.node.preview.childImageSharp.fluid} />
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="w-col w-col-8 w-col-stack">
                      <h4 className="invert-text-color">{document.node.name}</h4>
                      <ReactMarkdown source={document.node.description} />
                    </div>
                  </div> : <span /> }
                </div>
              ))}
              <div className="w-row" style={{ minHeight:"235px" }}>
                <div className="w-col w-col-4 w-col-stack">
                  <div className="product-wrapper w-col w-col-3 w-col-medium-4 w-col-small-6" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <div className="security-video-cover">
                      <img alt="" height="250px" width="250px" src="/static/images/ghosting_black_uasmne.jpg" />
                    </div>
                  </div>
                </div>
                <div className="w-col w-col-8 w-col-stack">
                  <h4 className="invert-text-color">Ghosting*</h4>
                  <p><i>Image or text can only be revealed under flashlight</i></p>
                  <p><i>Features: Covert Text and Image</i></p>
                </div>
              </div>
              <div className="w-row" style={{ minHeight:"235px" }}>
                <div className="w-col w-col-4 w-col-stack">
                  <div className="product-wrapper w-col w-col-3 w-col-medium-4 w-col-small-6" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <div className="security-video-cover">
                      <img alt="" height="250px" width="250px" src="/static/images/hidden_image_black_gedus5.jpg" />
                    </div>
                  </div>
                </div>
                <div className="w-col w-col-8 w-col-stack">
                  <h4 className="invert-text-color">Hidden Image*</h4>
                  <p><i>Image or text can only be revealed under green laser light</i></p>
                  <p><i>Features: Covert Text and Image</i></p>
                </div>
              </div>
              <p>*Security features can only be revealed under special reading devices.</p>
            </div>
          </section>

          <section className="section">
            <div className="container w-container">
              <div className="w-col w-col-12 w-col-stack">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                  <div className="w-row">
                    <h2>Creative Design</h2>
                    <h3>Our extensive product line offers endless possibilities with your creativity, hundreds of holographic and Fresnel lens patterns will take you into a whole new scope. K Laser can provide samples to help you with new ideas, we can also offer collaborative design and sample printing services upon request.</h3>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <img alt="" style={{ padding:"5%"}} src="/static/images/dpittszcn4ldhqsi8vtc.jpg" />
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                  <h3>K Laser support qualified designers and design students with sample cards or tips on product applications. We can also hold workshops at studios or colleges on topics of creative design and product applications upon request.</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                  <div className="w-row" style={{ marginTop: "100px" }}>
                    <h2>Technical Support</h2>
                    <h3>Do you need more information on product specification or seek advice on applications? Our professional team at K Laser can provide answers to your questions and offer advice. We can also recommend reputable partners for your projects. Contact us for technical support.</h3>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </Layout>
    )
  }
}
export default ServiceEnPage;

export const pageQuery = graphql`  
  query ServiceEnQuery {
    allStrapiService {
      edges {
        node {
          id
          description
          language
        }
      }
    }
    allStrapiMotion (sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          name
          video_url
          label
          language
          description
          preview {
            childImageSharp {
              fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
